import { Badge, Checkbox, Input, List, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { Skill, StudentSkill } from "../models/models";

const StudentSkillList: React.FC<{ userId: string | undefined, ready: boolean, setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({ userId, ready, setReady }) => {
    const [allSkills, setAllSkills] = useState<Array<Skill>>([]);
    const [studentSkills, setStudentSkills] = useState<Array<StudentSkill>>([]);
    const [skillsFilter, setSkillsFilter] = useState("");

    const context = useContext(AppContext);

    useEffect(() => {
        if (!userId || !ready) {
            return;
        }

        (async () => {
            const response = await fetch(`/api/Skill`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                setAllSkills(info);
            } else {
                setAllSkills([]);
            }
        })();


    }, [userId, ready]);

    useEffect(() => {
        (async () => {
            const response = await fetch(`/api/Skill/Student/${userId}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info: Array<StudentSkill> = await response.json();
                let studentSkillDictionary = info.reduce((result: { [skillId: number]: StudentSkill }, filter) => {
                    result[filter.skillId] = filter;
                    return result;
                }, {});

                let allSkillsArray = allSkills.map(allSkill => {
                    let exists = studentSkillDictionary[allSkill?.id ?? -1];

                    return { checked: exists?.checked, name: allSkill.name, parent: allSkill.parent, date: exists?.date, id: exists?.id, skillId: allSkill.id, studentId: userId, professorId: exists?.professorId } as StudentSkill;
                });

                allSkillsArray = allSkillsArray.sort((a, b) => { return (!a.id && !b.id) ? 0 : a.id ? -1 : 1 });

                setStudentSkills(allSkillsArray);
            } else {
                setStudentSkills([]);
            }
        })();
    }, [allSkills])

    const approveSkill = async (skill: StudentSkill) => {
        if (!skill.skillId) {
            return;
        }

        const response = await fetch(`/api/Skill/CheckSkillStudent`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                skillId: skill.skillId,
                studentId: userId
            })
        });

        if (response.status === 200) {
            message.success("Αποθηκεύτηκε");
            setReady(false);
            return;
        }

        message.error("Συνέβη κάποιο σφάλμα");
    }

    const getItemActions = (skill: StudentSkill) => {
        if (!skill.parent) {
            return [];
        }

        let t = context?.[0].user?.student ? "Ολοκληρώθηκε" : "Εγκρίση"
        let actions = [<Checkbox disabled={skill?.id != null} checked={skill?.id != null} onClick={() => { approveSkill(skill); }}>{t}</Checkbox>];

        if (skill.id != null) {
            actions = [<Badge status={skill.checked ? "success" : "warning"} text={skill.checked ? "Εγκρίθηκε" : "Αναμονή για έγκριση"} />];
        }

        return actions;
    }

    return <>{studentSkills &&
        <div id="scrollableDiv" style={{
            maxHeight: 400,
            overflow: 'auto',
        }}
        >
            <Input.Search value={skillsFilter} onChange={(e) => { setSkillsFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton style={{ marginBottom: "10px" }} />
            <List>{studentSkills.filter(ss => ss.name.toLowerCase().includes(skillsFilter)).map((skill, i) =>
                <List.Item key={i} actions={getItemActions(skill)} className={skill.parent ? "" : "header-col"}>{skill.name}</List.Item>)
            }</List>
        </div>
    }
    </>;
}

export default StudentSkillList;