import { Avatar, message, Button, Card, Col, List, PageHeader, Space, Switch, Typography, Badge, Modal, Row, Descriptions, Grid, Table, Statistic } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import { AvailableSemester, Enrollment, HomePageStats, NewCourseValues, Skill, UserInfo } from '../models/models';
import { PlusOutlined, DownloadOutlined, CalendarTwoTone, CalendarFilled, ExclamationCircleOutlined, DownOutlined, ExportOutlined } from '@ant-design/icons';
import NewCourseModal from '../components/NewCourseModal';
import UsersTable from '../components/UsersTable';
import SkillsTable from '../components/SkillsTable';
import StudentSkillList from '../components/StudentSkillList';
import ProfessorUsersTable from '../components/ProfessorUsersTable';

const Dashboard: React.FC = props => {
    const context = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [enrollments, setEnrollments] = useState<Array<Enrollment>>([]);
    const [openCourses, setOpenCourses] = useState<Array<AvailableSemester>>([]);
    const [isCourseModalOpen, setIsCourseModalOpen] = useState<NewCourseValues | null>(null);
    const [users, setUsers] = useState<Array<UserInfo>>([]);
    const [skills, setSkills] = useState<Array<Skill>>([]);
    const [homePageStats, setHomePageStats] = useState<HomePageStats>({ newComments: 0, newPresences: 0, newSkills: 0, newUsers: 0 });


    const navigate = useNavigate();
    const screens = Grid.useBreakpoint();

    useEffect(() => {
        if (ready) {
            return;
        }

        (async () => {
            const response = await fetch("/api/Courses", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                setEnrollments(info);
            } else {
                setEnrollments([]);
            }

            setReady(true);
        })();

        (async () => {
            const response = await fetch("/api/Courses/Available", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const info: Array<AvailableSemester> = await response.json();
                if (isProfessorOrAdmin()) {
                    setOpenCourses(info.sort(a => { return a.isEnrolled ? -1 : 1 }));
                    return;
                }

                setOpenCourses(info);
            } else {
                setOpenCourses([]);
            }
        })();

        if (!context[0]?.user?.admin && !context[0].user?.professor) {
            return;
        }

        (async () => {
            const response = await fetch("/api/User/All", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const usrs: Array<UserInfo> = await response.json();
                setUsers(usrs);
            } else {
                setUsers([]);
            }
        })();

        if (!context[0]?.user?.admin) {
            return;
        }

        (async () => {
            const response = await fetch("/api/Skill", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const skls: Array<Skill> = await response.json();
                setSkills(skls);
            } else {
                setSkills([]);
            }
        })();

        (async () => {
            const response = await fetch("/api/user/Home", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                setHomePageStats(await response.json());
            } else {
                setHomePageStats({ newComments: 0, newPresences: 0, newSkills: 0, newUsers: 0 });
            }
        })();
    }, [ready]);

    const enrollOk = async (semesterId: number, courseId: number) => {
        const response = await fetch("/api/Courses/Enroll", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterId
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την εγγραφή σας στο μάθημα");
            return;
        }

        const info = await response.json();
        setReady(false);
    }

    const enroll = async (semesterId: number, courseId: number) => {
        Modal.confirm({
            title: 'Επιβεβαίωση εγγραφής',
            content: 'Είσαι σίγουρος οτι θέλεις να εγγραφείς στο μάθημα;',
            okText: 'Ναι',
            cancelText: 'Άκυρο',
            onOk() {
                enrollOk(semesterId, courseId);
            },
            onCancel() {
            },
        });
    }

    const changeEnrollmentState = async (semesterId: number, courseId: number) => {
        const response = await fetch("/api/Courses/ChangeEnrollmentState", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterId
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την αλλαγή της καταστασης εγγραφών στο μάθημα");
            return;
        }

        const info = await response.json();
        setReady(false);
    }

    const isProfessorOrAdmin = () => {
        return context[0].user?.professor || context[0].user?.admin;
    }

    const getCourseDescription = (item: Enrollment) => {
        return <>
            <strong>Κωδικός: </strong>{item.course.code}<br />
            <strong>Ημ/νια: </strong><span>{`${moment(item.semester.startDate).format("ddd, DD MMM YYYY")} - ${moment(item.semester.endDate).format("ddd, DD MMM YYYY")}`}</span><br />
            {item.professors && <><strong>Επόπτες καθηγητές: </strong><span><br />
                {item.professors && item.professors.map(p => <>{p.name} <small>{" (" + p.email + ")"}</small> <br /></>)}
            </span>
            </>
            }</>;
    }

    const downloadPDF = (pdfLink: string) => {
        window.open(`/Uploads/${pdfLink}`, "_blank");
    }

    const createEditCourse = async (values: NewCourseValues) => {
        const response = await fetch("/api/Courses/Add", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                id: values?.id,
                name: values.title,
                semester: values.semester,
                startDate: values.dates[0].toDate(),
                endDate: values.dates[1].toDate(),
                code: values.code,
                timeStart: values.timeStart,
                timeEnd: values.timeEnd,
                minimumComments: values.minimumComments,
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα δημιουργία/επεξεργασία του μαθήματος");
            return;
        }

        setIsCourseModalOpen(null);
        const info = await response.json();
        setReady(false);
    }

    const deleteEnrollOk = async (semesterId: number) => {
        const response = await fetch("/api/Courses/DeleteEnroll", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterId
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την διαγραφή σας στο μάθημα");
            return;
        }

        const info = await response.json();
        setReady(false);
    }

    const deleteEnroll = async (semesterId: number) => {
        Modal.confirm({
            title: 'Επιβεβαίωση διαγραφής',
            icon: <ExclamationCircleOutlined />,
            content: 'Είσαι σίγουρος οτι θέλεις να διαγραφείς απο το μάθημα;',
            okText: 'Ναι',
            cancelText: 'Άκυρο',
            onOk() {
                deleteEnrollOk(semesterId);
            },
            onCancel() {
            },
        });
    }

    const handleMenuClick2 = (courseId: number) => {
        window.open(`/api/Courses/ExportStudentPresences/${courseId}`, '_blank');
    };

    return (<>
        <Badge.Ribbon text={context[0].user?.admin ? "Admin" : context[0].user?.professor ? "Professor" : "Student"}>
            <PageHeader
                className="site-page-header-responsive"
                title={`Καλωσήρθες, ${context[0]?.user?.firstName} ${context[0]?.user?.lastName} (${context[0].user?.email})`}
            >
            </PageHeader>
            <Content>
                {isProfessorOrAdmin() &&
                    <>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Card title="Στατιστικά τελευταίας εβδομάδας" bordered hoverable className="always">
                                    <Card.Grid style={{ width: "25%" }}><Statistic
                                        title="Νέα σχόλια"
                                        value={homePageStats.newComments} /></Card.Grid>
                                    <Card.Grid style={{ width: "25%" }}><Statistic
                                        title="Νέα παρουσίες"
                                        value={homePageStats.newPresences} /></Card.Grid>
                                    <Card.Grid style={{ width: "25%" }}><Statistic
                                        title="Νέοι χρήστες"
                                        value={homePageStats.newUsers} /></Card.Grid>
                                    <Card.Grid style={{ width: "25%" }}><Statistic
                                        title="Νέες δεξιότητες"
                                        value={homePageStats.newSkills} /></Card.Grid>
                                </Card>
                            </Col>
                        </Row>
                        {context[0].user?.admin &&
                            <Row justify='end'>
                                <Space direction='horizontal'>
                                    <Button type="link" href="/Identity/Account/RegisterProfessor?returnUrl=/">Δημιουργία λογαριασμού καθηγητή</Button>
                                </Space>
                            </Row>
                        }
                        <Card title="Μαθήματα" bordered hoverable className="always"
                            extra={
                                context[0].user?.admin ? <Button type="primary" onClick={() => { setIsCourseModalOpen({ code: "", minimumComments: 11, semester: "", title: "", dates: [], timeStart: 7, timeEnd: 10 }); }}><PlusOutlined />Προσθήκη νέου μαθήματος</Button> : null
                            }>
                            <List
                                dataSource={openCourses}
                                renderItem={item => (
                                    <List.Item key={item.id}>
                                        <List.Item.Meta
                                            title={<span>{item.course.name} - {item.name}</span>}
                                            description={`${moment(item.startDate).format("ddd, DD MMM YYYY")} - ${moment(item.endDate).format("ddd, DD MMM YYYY")}`}
                                        />
                                        <Space wrap  direction='horizontal'>
                                            {!item.isEnrolled && context[0].user?.professor &&
                                                <Button type="default" onClick={() => { enroll(item.id, item.courseId); }}>Εγγραφή καθηγητή</Button>
                                            }
                                            {(item.isEnrolled || context[0].user?.admin) &&
                                                <Space wrap size={0} direction="horizontal">
                                                    {context[0].user?.admin && <>
                                                        <span>Ενεργοποίηση εγγραφών</span>
                                                        <Switch defaultChecked={item.enrollmentsOpen} onChange={() => { changeEnrollmentState(item.id, item.courseId) }} />
                                                        <Button type="primary" onClick={() => { setIsCourseModalOpen({ id: item.id, code: item.course.code, minimumComments: item.minimumComments, semester: item.name, title: item.course.name, dates: [moment(item.startDate), moment(item.endDate)], timeStart: item.timeStart, timeEnd: item.timeEnd }); }}>Επεξεργασία</Button>
                                                    </>
                                                    }
                                                    <Button type="primary" onClick={() => { navigate(`/professorCourse/${item.id}`, { replace: true }) }}>Μετάβαση στο μάθημα</Button>
                                                    <Button type="primary" onClick={() => { handleMenuClick2(item.courseId); }} title="Εξαγωγή Παρουσιών"> <ExportOutlined /> </Button>
                                                </Space>
                                            }
                                            <Space direction='vertical'>
                                                <Badge color="blue" text={"Εγγεγραμμένοι " + item.allStudents} />
                                                <Badge color="green" text={"Ολοκληρώθηκαν " + item.checkedStudents} />
                                            </Space>
                                        </Space>
                                    </List.Item>)}></List>
                        </Card>
                        {context[0].user?.admin &&
                            <Card title="Χρήστες" bordered hoverable className="always" style={{ marginTop: "24px" }}>
                                <UsersTable users={users} setReady={setReady} />
                            </Card>}
                        {context[0].user?.professor &&
                            <Card title="Φοιτητές" bordered hoverable className="always" style={{ marginTop: "24px" }}>
                                <ProfessorUsersTable users={users.filter(u => u.student)} setReady={setReady} />
                            </Card>}
                        {context[0].user?.admin &&
                            <SkillsTable skills={skills} setReady={setReady} />
                        }
                    </>
                }
                {!isProfessorOrAdmin() &&
                    <>
                        <Card title="Μαθήματα που έχετε εγγραφεί" bordered hoverable className="always">
                            <List
                                className="courses-list"
                                dataSource={enrollments}
                                renderItem={item => (
                                    <List.Item key={`${item.semesterId} - ${item.courseId}`}
                                        extra={<Space wrap direction='horizontal' size={0}>
                                            {item.course && item.course.extraPDF &&
                                                <Button size={screens["xs"] ? "large" : "middle"} type="default" onClick={() => { downloadPDF(item.course.extraPDF); }}><DownloadOutlined /> Πληροφορίες μαθήματος</Button>
                                            }
                                            <Button size={screens["xs"] ? "large" : "middle"} type="primary" onClick={() => { navigate(`/course/${item.semesterId}`, { replace: true }) }}><CalendarFilled /> Μετάβαση</Button>
                                            <Button size={screens["xs"] ? "large" : "middle"} danger disabled={item.hasComments} title={item.hasComments ? "Δεν μπορείτε να διαγραφείτε απο το μάθημα γιατί έχετε ήδη συμπληρώσει σχόλια" : "Διαγραφή"} type="primary" onClick={() => { deleteEnroll(item.semesterId); }}> Απεγγραφή</Button>
                                        </Space>}>
                                        <List.Item.Meta
                                            title={<span>{item.course.name} - {item.semester.name}</span>}
                                            description={getCourseDescription(item)}
                                        >
                                        </List.Item.Meta>
                                    </List.Item>)}></List>
                        </Card>
                        <Card title="Διαθέσιμα Μαθήματα" bordered hoverable className="always" style={{ marginTop: "15px" }}>
                            <List
                                locale={{ emptyText: "Δεν υπάρχουν διαθέσιμα μαθήματα" }}
                                dataSource={openCourses}
                                renderItem={item => (
                                    <List.Item key={item.id}>
                                        <List.Item.Meta
                                            title={<span>{item.course.name} - {item.name}</span>}
                                            description={`${moment(item.startDate).format("ddd, DD MMM YYYY")} - ${moment(item.endDate).format("ddd, DD MMM YYYY")}`}
                                        />
                                        <Space wrap direction='horizontal'>
                                            {item.enrollmentsOpen && !isProfessorOrAdmin() &&
                                                <Button size={screens["xs"] ? "large" : "middle"} type="primary" onClick={() => { enroll(item.id, item.courseId); }}>Εγγραφή</Button>
                                            }
                                            {isProfessorOrAdmin() &&
                                                <>
                                                    <span>Ενεργοποίηση εγγραφών</span>
                                                    <Switch defaultChecked={item.enrollmentsOpen} onChange={() => { changeEnrollmentState(item.id, item.courseId) }} />
                                                    <Button type="primary" onClick={() => { navigate(`/professorCourse/${item.id}`, { replace: true }) }}>Μετάβαση στο μάθημα</Button>

                                                </>
                                            }
                                        </Space>
                                    </List.Item>)}></List>
                        </Card>
                        <Card title="Δεξιότητες" bordered hoverable className="always" style={{ marginTop: "15px" }}>
                            <StudentSkillList userId={context[0]?.user?.id} ready={ready} setReady={setReady}></StudentSkillList>
                        </Card>
                    </>
                }
                <Card bordered hoverable className="always" style={{ marginTop: "50px" }}>
                    &copy; 2022 - Κλινικό Portfolio Τμήμα Νοσηλευτικής - Powered by <a href="https://biostats.gr/e-crf-edc/">biostats.gr</a>
                </Card>
            </Content>
        </Badge.Ribbon>

        <NewCourseModal open={isCourseModalOpen != null} onCreate={createEditCourse} onCancel={() => { setIsCourseModalOpen(null); }} course={isCourseModalOpen} ></NewCourseModal>
    </>);
}

export default Dashboard;
