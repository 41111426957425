import { CheckCircleTwoTone, EnvironmentTwoTone, ExclamationCircleOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Calendar, Card, Col, Descriptions, Form, Grid, Input, Layout, List, Menu, message, Modal, PageHeader, Row, Space, Spin, Table, TableColumnsType, Tabs, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import ProfessorCommentTable from "../components/ProfessorCommentTable";
import { CommentColumnType, SemesterComments, Skill, StudentComment, StudentInfo, StudentPresence, StudentSkill, WeekComment } from "../models/models";
import '../ProfessorCourse.css';
import el_gr from 'antd/lib/calendar/locale/el_GR';
import { green, blue, red } from '@ant-design/colors';
import { useReactToPrint } from 'react-to-print';

const Course: React.FC = props => {
    const context = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [semesterComments, setSemesterComments] = useState<SemesterComments | null>(null);
    const [students, setStudents] = useState<Array<StudentInfo>>([]);
    const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);
    const [weeks, setWeeks] = useState<Array<WeekComment>>([]);
    const [search, setSearch] = useState("");
    const [allSkills, setAllSkills] = useState<Array<Skill>>([]);

    let { id } = useParams();

    const screens = Grid.useBreakpoint();

    useEffect(() => {
        if (ready || id == null) {
            return;
        }

        (async () => {
            const response = await fetch(`/api/Courses/Enrollments/${id}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                // for (let i = 0; i < 50; i++) {
                //     info.push({ ...info[i % info.length], id: i + 100, name: info[i % info.length].name + i.toString() });
                // }
                setStudents(info);
                if (selectedStudentId == null) {
                    setSelectedStudentId(info?.[0].id);
                }
            } else {
                setStudents([]);
            }

            setReady(true);
        })();

        (async () => {
            const response = await fetch(`/api/Courses/LabComments/${id}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                setSemesterComments(info);
            } else {
                setSemesterComments(null);
            }

            setReady(true);
        })();


        (async () => {
            const response = await fetch(`/api/Skill`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                setAllSkills(info);
            } else {
                setAllSkills([]);
            }

            setReady(true);
        })();

    }, [ready, id]);

    useEffect(() => {
        if (!semesterComments) {
            setWeeks([]);
            return;
        }

        var studentComments = semesterComments.comments.filter(c => c.studentId == selectedStudentId);

        let start = moment(semesterComments.startDate).clone().utc().startOf('week');
        let end = moment(semesterComments.endDate).clone().utc().startOf('week');
        let week = start.clone();
        let weeksMoment = [];
        let weeks: Array<WeekComment> = [];

        while (week.isSameOrBefore(end, 'week')) {
            weeksMoment.push(week.clone());

            let commentsInWeek = studentComments.filter(c => moment(c.date).startOf('week').isSame(week.clone().startOf('week'), 'week')) ?? null;
            // if (isProfessorOrAdmin() && commentsInWeek.length == 0) {
            //     continue;
            // }

            weeks.push({
                weekDate: week.clone(),
                weekNumber: week.diff(start, 'week') + 1,
                comments: (!commentsInWeek || commentsInWeek.length == 0) ? [] : commentsInWeek.map(ciw => {
                    return {
                        checked: ciw?.checked ?? false,
                        comment: ciw?.comment ?? "",
                        id: ciw?.id ?? null,
                        commentDate: (ciw && ciw.date) ? moment(ciw.date) : null,
                        submittedDate: (ciw && ciw.submittedDate) ? moment(ciw.submittedDate) : null,
                        professorName: ciw?.professorName,
                        professorText: ciw?.professorText ?? "",
                        professorCheckedId: ciw?.professorCheckedId,
                    }
                })
            });

            week.add(1, 'week');
        }

        setWeeks(weeks);
    }, [semesterComments, selectedStudentId]);

    const isProfessorOrAdmin = () => {
        return context[0].user?.professor || context[0].user?.admin;
    }

    const studentChange = (activeKey: string) => {
        setSelectedStudentId(activeKey);
    }

    const checkAllForStudent = async (studentId: string) => {
        const response = await fetch(`/api/Courses/CheckAllForStudent/${id}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                studentId: studentId
            })
        });

        if (response.status === 200) {
            message.success("Αποθηκεύτηκε");
            setReady(false);
            return;
        }
    }

    const getTabLabel = (s: StudentInfo) => {

    }

    const isStudentInSearch = (s: StudentInfo, searchValue: string) => {
        if (!searchValue) {
            return true;
        }

        searchValue = searchValue.toLowerCase();
        return s.email.toLowerCase().includes(searchValue) || s.name.toLowerCase().includes(searchValue);
    }

    const onSearch = (value: string, event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>) => {
        setSearch(value);
        let firstStudentFound = students.find(s => isStudentInSearch(s, value));
        setSelectedStudentId(firstStudentFound?.id ?? null);
    }

    const deleteEnrollOk = async (studentId: string) => {
        const response = await fetch("/api/Courses/DeleteEnroll", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterComments?.semesterId,
                userId: studentId,
            })
        });

        if (response.status !== 200) {
            let errorMessage = await response.json();
            message.error(errorMessage ?? "Συνέβει ένα σφάλμα κατα την διαγραφή του φοιτητή απο το μάθημα");
            return;
        }

        const info = await response.json();
        setReady(false);
    }

    const deleteEnrollStudent = async (studentId: string) => {
        Modal.confirm({
            title: 'Επιβεβαίωση διαγραφής',
            icon: <ExclamationCircleOutlined />,
            content: 'Είσαι σίγουρος οτι θέλεις να διαγραψεις το φοιτητή απο το μάθημα;',
            okText: 'Ναι',
            cancelText: 'Άκυρο',
            onOk() {
                deleteEnrollOk(studentId);
            },
            onCancel() {
            },
        });
    }

    const getTabItems = () => {
        let studentsFound = students.filter(s => isStudentInSearch(s, search));
        if (studentsFound.length == 0 && students.length > 0 && search) {
            return [{
                key: "NotFound",
                label: "Δεν βρεθηκαν φοιτητές",
                children:
                    <React.Fragment key={"NotFound"}>
                        <Row style={{ marginBottom: "24px" }}>
                            <Card hoverable className="always" style={{ width: "100%" }}>
                                <Typography.Text>Δεν βρεθηκαν φοιτητές που να περιέχουν το αναγνωριστικό `{search}`</Typography.Text>
                            </Card>
                        </Row>
                    </React.Fragment >
            }]
        }

        return studentsFound.map(s => {
            return {
                label: <StudentTabLabel s={s} semesterComments={semesterComments}></StudentTabLabel>,
                key: `${s.id}`,
                children: <React.Fragment key={s.id}>
                    <Row style={{ marginBottom: "24px" }}>
                        <Card hoverable className="always">
                            <Descriptions layout="horizontal" size={"small"} column={screens["xl"] ? 4 : screens["lg"] ? 3 : screens["md"] ? 2 : 1}>
                                <Descriptions.Item label="Όνομα" span={2}><strong>{s.name}</strong></Descriptions.Item>
                                <Descriptions.Item label="Email" span={1}>{s.email}</Descriptions.Item>
                                <Descriptions.Item label="Αριθμός φοιτητικού μητρώου"><strong>{s.studentNumber}</strong></Descriptions.Item>
                                <Descriptions.Item label="Αριθμός φοιτητικής ταυτότητας" span={2}>{s.studentIdentityNumber}</Descriptions.Item>
                                <Descriptions.Item label="Ημερομηνία γέννησης">{moment(s.birthDate).format("DD MMM YYYY")}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Row>
                    <Row justify="end" style={{ marginBottom: "5px" }}>
                        {context[0].user?.professor &&
                            <Button style={{ marginRight: "250px" }} type="primary" onClick={() => { checkAllForStudent(s.id) }}>Εγκρίση όλων</Button>
                        }
                        {context[0].user?.admin && <Space wrap direction="horizontal" size={0}>
                            <Button type="primary" danger onClick={() => { deleteEnrollStudent(s.id) }}> Διαγραφή φοιτητή</Button>
                        </Space>
                        }
                    </Row>
                    <ProfessorContent semesterComments={semesterComments} weeks={weeks} ready={ready} setReady={setReady} selectedStudentId={selectedStudentId} allSkills={allSkills} student={s} />
                </React.Fragment>
            }
        })
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (<><PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title={semesterComments && `${semesterComments.course.name} - ${semesterComments.name}`}
    ></PageHeader>
        <Content className="professor-course-content">
            <h3>{`${moment(semesterComments?.startDate).format("ddd, DD MMM YYYY")} - ${moment(semesterComments?.endDate).format("ddd, DD MMM YYYY")}`}</h3>
            {!semesterComments && <><Spin indicator={antIcon} size="large" /><Typography.Text>  Παρακαλώ περιμένετε</Typography.Text></>}
            {semesterComments &&
                <Layout>
                    <Tabs
                        size="small"
                        tabPosition="left"
                        tabBarExtraContent={{
                            left: <Space wrap align="center" direction="horizontal">
                                {screens["sm"] && <Input.Search allowClear defaultValue={search} style={{ width: "150px" }} onSearch={onSearch} />}
                                <Badge count={students.length} overflowCount={999} />
                            </Space >
                        }}
                        style={{ height: "100%", paddingLeft: 0 }}
                        tabBarGutter={0}
                        activeKey={selectedStudentId ?? ""}
                        onTabClick={(activeKey) => { studentChange(activeKey) }}
                        items={getTabItems()}
                    ></Tabs>
                </Layout>
            }
        </Content>
    </>);
}

const StudentTabLabel: React.FC<{ s: StudentInfo, semesterComments: SemesterComments | null, }> = ({ s, semesterComments, ...props }) => {
    const screens = Grid.useBreakpoint();
    const [presences, setPresences] = useState<Array<StudentPresence>>([]);
    const [presencesCount, setPresenceCount] = useState(0);

    useEffect(() => {
        if (!semesterComments || !semesterComments.studentPresences) {
            setPresences([]);
            return;
        }

        let stP = semesterComments?.studentPresences.filter(sp => sp.studentId == s.id);
        setPresences(stP);
        let sortedStp = stP.sort((a, b) => a.date.valueOf() - b.date.valueOf());
        let count = 0;
        let lastDate: moment.Moment = moment("19700101");
        for (let i = 0; i < sortedStp.length; i++) {
            if (moment(sortedStp[i].date).isSame(lastDate, 'day')) {
                continue;
            }
            count++;
            lastDate = moment(sortedStp[i].date);
        }

        setPresenceCount(count);
    }, [semesterComments]);

    return (<>{!screens["sm"] &&
        <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
            <Meta
                avatar={<Typography.Text title={`${s.name} (${s.email}) `}><Avatar>{s.name[0] + s.name.split(" ").filter(e => e)[1][0]}</Avatar></Typography.Text>}
            />
            <span><EnvironmentTwoTone style={{ marginRight: 0 }} />{presencesCount}</span>
        </Space>}

        {screens["sm"] && <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}><Meta style={{ maxWidth: "250px" }}
            avatar={<Typography.Text title={`${s.name} (${s.email}) `}><Avatar style={{ backgroundColor: "" }}>{s.name[0] + s.name.split(" ").filter(e => e)[1][0]}</Avatar></Typography.Text>}
            title={<><Typography.Text style={{ marginRight: "15px" }} ellipsis={true} title={s.name}>{s.name}</Typography.Text></>}
            description={<Typography.Text ellipsis={true} title={s.email}>{s.email}</Typography.Text>}
        />
            <span><EnvironmentTwoTone style={{ marginRight: 0 }} />{presencesCount}</span>
        </Space>}
    </>)
}

const ProfessorContent: React.FC<{ semesterComments: SemesterComments | null, weeks: WeekComment[], ready: boolean, setReady: React.Dispatch<React.SetStateAction<boolean>>, selectedStudentId: string | null, allSkills: Array<Skill>, student: StudentInfo }> = ({ semesterComments, weeks, ready, setReady, selectedStudentId, allSkills, student, ...props }) => {
    const [presences, setPresences] = useState<Array<StudentPresence>>([]);
    const [studentSkills, setStudentSkills] = useState<Array<StudentSkill>>([]);
    const [presencesCount, setPresenceCount] = useState(0);
    var lastPanelChange = false;

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: semesterComments ? `${semesterComments?.course.name} - ${semesterComments?.name}` : "Αναφορά"
    });


    useEffect(() => {
        if (!semesterComments || !semesterComments.studentPresences) {
            setPresences([]);
            return;
        }

        let stP = semesterComments?.studentPresences.filter(sp => sp.studentId == selectedStudentId);
        setPresences(stP);
        let sortedStp = stP.sort((a, b) => a.date.valueOf() - b.date.valueOf());
        let count = 0;
        let lastDate: moment.Moment = moment("19700101");
        for (let i = 0; i < sortedStp.length; i++) {
            if (moment(sortedStp[i].date).isSame(lastDate, 'day')) {
                continue;
            }
            count++;
            lastDate = moment(sortedStp[i].date);
        }

        setPresenceCount(count);
    }, [semesterComments]);

    useEffect(() => {
        if (!selectedStudentId || !ready) {
            return;
        }

        (async () => {
            const response = await fetch(`/api/Skill/Student/${selectedStudentId}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info: Array<StudentSkill> = await response.json();
                let studentSkillDictionary = info.reduce((result: { [skillId: number]: StudentSkill }, filter) => {
                    result[filter.skillId] = filter;
                    return result;
                }, {});

                let allSkillsArray = allSkills.map(allSkill => {
                    let exists = studentSkillDictionary[allSkill?.id ?? -1];

                    return { checked: exists?.checked, name: allSkill.name, date: exists?.date, id: exists?.id, skillId: allSkill.id, studentId: selectedStudentId, professorId: exists?.professorId } as StudentSkill;
                });

                allSkillsArray = allSkillsArray.sort((a, b) => { return (!a.id && !b.id) ? 0 : a.id ? -1 : 1 });

                setStudentSkills(allSkillsArray);
            } else {
                setStudentSkills([]);
            }
        })();
        setReady(true);
    }, [selectedStudentId, ready]);

    const dateCellRender = (value: moment.Moment) => {
        let foundDate = presences.filter(p => moment(p.date).isSame(value, 'day'));
        if (foundDate.length == 0) {
            return <></>
        }

        let localDate = moment.utc(foundDate[0].date).local();
        let color = blue[6];
        if (localDate.local().hours() < (semesterComments?.timeStart ?? 0)) {
            color = red[6];
        }

        if (localDate.local().hours() > (semesterComments?.timeEnd ?? 0)) {
            color = red[6];
        }

        color = foundDate.filter(fd => fd.checked).length > 0 ? green[6] : color;


        return (
            <Space direction="vertical" size={0}>
                <CheckCircleTwoTone twoToneColor={color} style={{ fontSize: "24px" }} />
                <Typography.Text>{localDate.local().format("LT")}</Typography.Text>
            </Space>);
    };

    const addProfessorPresence = async (date: moment.Moment) => {
        let sd = moment.utc(date.format('MM/DD/YYYY'), 'MM/DD/YYYY');
        const response = await fetch(`/api/Courses/ProfessorStudentPresence`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterComments?.semesterId,
                userId: selectedStudentId,
                date: sd
            })
        });

        if (response.status === 200) {
            message.success("Αποθηκεύτηκε");
            setReady(false);
            return;
        }

        message.error("Συνέβη κάποιο σφάλμα");
    }

    const onSelect = (value: moment.Moment) => {
        let lpc = lastPanelChange == true;
        let foundDate = presences.filter(p => moment(p.date).isSame(value, 'day') && !p.checked);
        if (foundDate.length == 0) {
            if (lpc) {
                lastPanelChange = false;
                return;
            }

            Modal.confirm({
                title: 'Προσθήκη παρουσίας',
                icon: <ExclamationCircleOutlined />,
                content: 'Είσαι σίγουρος οτι θέλεις να προσθέσεις παρουσία για το φοιτητή στο μάθημα;',
                okText: 'Ναι',
                cancelText: 'Άκυρο',
                onOk() {
                    addProfessorPresence(value);
                },
                onCancel() {
                },
            });

            return;
        }

        lastPanelChange = false;
        checkPresence(foundDate[0].id);
    }

    const onPanelChange = (value: moment.Moment, mode: string) => {
        lastPanelChange = true;
    }

    const checkPresence = async (id: number) => {
        const response = await fetch(`/api/Courses/StudentPresenceCheck/${id}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
        });

        if (response.status === 200) {
            message.success("Αποθηκεύτηκε");
            setReady(false);
            return;
        }

        message.error("Συνέβη κάποιο σφάλμα");
    }

    const approveSkill = async (skill: StudentSkill) => {
        if (!skill.skillId) {
            return;
        }

        const response = await fetch(`/api/Skill/CheckSkillStudent`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                skillId: skill.skillId,
                studentId: selectedStudentId
            })
        });

        if (response.status === 200) {
            message.success("Αποθηκεύτηκε");
            setReady(false);
            return;
        }

        message.error("Συνέβη κάποιο σφάλμα");
    }

    const columns: TableColumnsType<StudentComment> = [
        { title: "Σχόλιο φοιτητή", dataIndex: 'comment', ellipsis: false, width: "40%" },
        {
            title: <>Ημ/νία παρακολούθησης</>, ellipsis: true,
            render: (value, record, index) => {
                return (<>{record.commentDate?.format("ddd, DD MMM YYYY")}</>)
            }
        },
        {
            title: "Σχόλια καθηγητή", ellipsis: true, dataIndex: 'professorText',
        }
    ];

    const hasFinished = () => {
        return semesterComments?.finishedStudents.includes(selectedStudentId ?? "") ?? false;
    }

    return <Tabs
        tabPosition="top"
        items={[
            {
                label: <span style={{ fontSize: "16px" }}>Ημερολόγιο</span>,
                key: "0",
                children:
                    <Row justify="start" gutter={[16, 16]} wrap style={{ marginBottom: "24px" }}>
                        {semesterComments && weeks.filter(w => w.comments.length > 0).map((w, i) =>
                            <Col span={24} key={w.weekNumber}>
                                <Card hoverable className="always" size="small" key={w.weekNumber} title={<><span>{w.weekNumber}ή Εβδομάδα - </span><Typography.Text type="secondary" style={{ fontSize: "90%" }}>{w.weekDate.format("ddd, DD MMM YYYY")} - {w.weekDate.clone().add(1, 'week').subtract(1, 'second').format("ddd, DD MMM YYYY")}</Typography.Text></>} bordered>
                                    <ProfessorCommentTable w={w} isFirst={i == 0} setReady={setReady} semesterId={semesterComments.semesterId}></ProfessorCommentTable>
                                </Card>
                            </Col>
                        )}
                    </Row>
            },
            {
                label: <span style={{ fontSize: "16px" }}>Παρουσιες</span>, key: "1",
                children:
                    <>
                        <Row justify="end">
                            <Typography.Title style={{ marginRight: "25px" }} level={4}>Σύνολο παρουσιών <strong> {presencesCount}</strong></Typography.Title>
                        </Row>
                        <Calendar fullscreen={true} mode="month" locale={el_gr} validRange={[moment(semesterComments?.startDate).utc().startOf('day'), moment(semesterComments?.endDate).endOf('day')]} dateCellRender={dateCellRender} onSelect={onSelect} onPanelChange={onPanelChange} />
                    </>
            }, {
                label: <span style={{ fontSize: "16px" }}>Αναφορα</span>, key: "3",
                children: <>
                    <style type="text/css" media="print">{"\
                        @page {\ size: portrait;\ margin: 2mm; }\
                        @media print {\
                            .page-break {\
                            margin-top: 1rem;\
                            display: block;\
                            page-break-before: auto;\
                            }\
                            .ant-table-wrapper {\
                                page-break-inside: avoid;\
                            }\
                            html, body {\
                                height: initial !important;\
                                overflow: initial !important;\
                                -webkit-print-color-adjust: exact;\
                              }\
                        }\
                    "}</style>

                    <Row justify="end" style={{ marginRight: 25, marginBottom: 25 }}>
                        <Button type="primary" onClick={handlePrint}>Εξαγωγή σε PDF</Button>
                    </Row>
                    <Row justify="start" gutter={[16, 16]} wrap style={{ marginBottom: "24px", height: "initial !important", overflow: "initial !important" }} ref={componentRef}>
                        <Col span={24}>
                            <img src="/logo_uoi_gpa.png" style={{ maxHeight: "250px", marginLeft: "auto", marginRight: "auto", display: "block" }} />
                        </Col>
                        <Col span={24}>
                            <Typography.Title style={{ textAlign: "center" }}>{semesterComments ? `${semesterComments?.course.name}` : ""}</Typography.Title>
                            <Typography.Title style={{ textAlign: "center", marginTop: 0 }}>{semesterComments ? `${semesterComments?.name}` : ""}</Typography.Title>
                        </Col>
                        <Col span={24} style={{ paddingLeft: 25, paddingRight: 25 }}>
                            <Card hoverable className="always" bodyStyle={{ backgroundColor: "#fafafa" }}>
                                <Descriptions layout="horizontal" size={"small"} column={3}>
                                    <Descriptions.Item label="Κατάσταση" span={3}><Badge status={hasFinished() ? "success" : "warning"} text={hasFinished() ? "Ολοκληρώθηκε" : "Σε εξέλιξη"}></Badge></Descriptions.Item>
                                    <Descriptions.Item label="Όνομα" span={3}><strong>{student.name}</strong></Descriptions.Item>
                                    <Descriptions.Item label="Email" span={3}>{student.email}</Descriptions.Item>
                                    <Descriptions.Item label="Αριθμός φοιτητικού μητρώου" span={3}><strong>{student.studentNumber}</strong></Descriptions.Item>
                                    <Descriptions.Item label="Αριθμός φοιτητικής ταυτότητας" span={3}>{student.studentIdentityNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Όνομα μητέρας">{student.motherName}</Descriptions.Item>
                                    <Descriptions.Item label="Όνομα πατέρα">{student.fatherName}</Descriptions.Item>
                                    <Descriptions.Item label="Ταυτότητα">{student.identityNumber} , {student.identityAuthority} </Descriptions.Item>
                                    <Descriptions.Item label="Διέθυνση">{student.street}, {student.city}, {student.postalCode}</Descriptions.Item>
                                    <Descriptions.Item label="Ημερομηνία γέννησης">{moment(student.birthDate).format("DD MMM YYYY")}</Descriptions.Item>
                                    <Descriptions.Item label="Τόπος γέννησης">{student.placeBirth}</Descriptions.Item>
                                    <Descriptions.Item label="Τηλέφωνα">{student.telephone} - {student.mobilePhone}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                        <div className="page-break"></div>
                        <Col span={24} style={{ paddingLeft: 25, paddingRight: 25 }}>
                            <Card hoverable className="always" size="small" bordered>
                                {semesterComments && weeks.map((w, i) =>
                                    <>
                                        <div className="page-break" />
                                        <Table locale={{ emptyText: "Δεν υπάρχουν εγγραφές" }}
                                            pagination={false}
                                            columns={columns} dataSource={w.comments} title={() => <><span>{w.weekNumber}ή Εβδομάδα - </span><Typography.Text type="secondary" style={{ fontSize: "90%" }}>{w.weekDate.format("ddd, DD MMM YYYY")} - {w.weekDate.clone().add(1, 'week').subtract(1, 'second').format("ddd, DD MMM YYYY")}</Typography.Text></>}></Table>
                                    </>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        ]}>
    </Tabs >
}

export default Course;

