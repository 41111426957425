import { AutoComplete, Badge, Checkbox, DatePicker, Form, Input, InputNumber, message, Modal, Select, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AvailableSemester, EditUserModalProps, Enrollment, UserInfo } from "../models/models";

const EditUserModal: React.FC<EditUserModalProps> = ({
    open,
    onSaved,
    onCancel,
    user,
    disabled
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (!open) {
            form.resetFields();
            return;
        }

        form.resetFields();
    }, [open]);

    useEffect(() => {
        if (user) {
            form.setFieldsValue({ ...user, password: "", birthDate: !user.birthDate ? "" : moment(user?.birthDate) })
        }

    }, [user]);

    const getRoleColor = (roles: Array<string> | undefined) => {
        if (!roles) {
            return "blue";
        }

        if (roles.includes("Admin")) {
            return "green";
        }

        if (roles.includes("Professor")) {
            return "orange";
        }

        return "blue";
    }

    const saveUser = async (user: UserInfo) => {
        if (!user || !user.id) {
            return;
        }

        let response = await fetch(`/api/User/Edit/${user.id}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(user)
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την επεξεργασία του χρήστη");
            onSaved(null);
            return;
        }

        onSaved(null);
    }

    return <Modal open={open}
        destroyOnClose
        width={"60%"}
        title="Επεξεργασία χρήστη"
        okText="Αποθήκευση"
        cancelText="Ακύρωση"
        onCancel={() => {
            form.resetFields();
            onCancel();
        }}
        onOk={() => {
            form
                .validateFields()
                .then(values => {
                    saveUser({ ...values, id: user?.id });
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
        }}
    >
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            disabled={disabled}
            initialValues={{ ...user, password: "", birthDate: user?.birthDate ? "" : moment(user?.birthDate) } ?? undefined}
        >
            <Badge.Ribbon text={user?.roles.join(", ")} color={getRoleColor(user?.roles)}>
                <Form.Item>
                    <h3 className="" style={{ textAlign: "center" }}>{user?.email}</h3>
                </Form.Item>
            </Badge.Ribbon>
            <hr />
            <Form.Item name="emailConfirmed" valuePropName="checked">
                <Checkbox>Επιβεβαιωμένο email</Checkbox>
            </Form.Item>

            <Input.Group compact>
                <Form.Item style={{ width: '50%' }} name="firstName" label={`Όνομα`} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '50%' }} name="lastName" label={`Επίθετο`} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Form.Item style={{ width: '50%' }} name="motherName" label={`Όνομα μητέρας`}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '50%' }} name="fatherName" label={`Όνομα πατέρα`}>
                    <Input />
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Form.Item style={{ width: '50%' }} name="studentNumber" label={`Αριθμός φοιτητικού μητρώου`}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '50%' }} name="studentIdentityNumber" label={`Αριθμός φοιτητικης ταυτότητας`}>
                    <Input />
                </Form.Item>
            </Input.Group>

            <Input.Group compact>
                <Form.Item style={{ width: '33%' }} name="semester" label={`Ακαδημαικό εξάμηνο`}>
                    <InputNumber style={{ width: '100%' }} min={1} max={24} />
                </Form.Item>
                <Form.Item style={{ width: '34%' }} name="birthDate" label={`Ημ/νια γέννησης`}>
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item style={{ width: '33%' }} name="placeBirth" label={`Τόπος γέννησης`}>
                    <Input />
                </Form.Item>
            </Input.Group>
            <Input.Group compact>
                <Form.Item style={{ width: '50%' }} name="identityNumber" label={`Αριθμός ταυτότητας`}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '50%' }} name="identityAuthority" label={`Εκδούσα αρχή`}>
                    <Input />
                </Form.Item>
            </Input.Group>

            <Input.Group compact>
                <Form.Item style={{ width: '34%' }} name="street" label={`Όδός`}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '33%' }} name="city" label={`Πόλη`}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '33%' }} name="postalCode" label={`Τ.Κ.`}>
                    <Input />
                </Form.Item>
            </Input.Group>

            <Input.Group compact>
                <Form.Item style={{ width: '50%' }} name="telephone" label={`Τηλέφωνο`}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ width: '50%' }} name="mobilePhome" label={`Κινητό`}>
                    <Input />
                </Form.Item>
            </Input.Group>
        </Form>
    </Modal>;
};

export default EditUserModal;