import { Form, Input, message, Modal, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { AvailableSemester, Enrollment, EnrollUserModalProps } from "../models/models";

const EnrollUserModal: React.FC<EnrollUserModalProps> = ({
    open,
    onCreate,
    onCancel,
    userId,
    userRealName,
    disabled
}) => {
    const [form] = Form.useForm();
    const [enrollments, setEnrollments] = useState<Array<Enrollment>>([]);
    const [allCourses, setAllCourses] = useState<Array<AvailableSemester>>([]);
    const [ready1, setReady1] = useState(false);
    const [ready2, setReady2] = useState(false);

    useEffect(() => {
        if (!open) {
            setReady1(false);
            setReady2(false);
            form.resetFields();
            return;
        }

        (async () => {
            const response = await fetch(`/api/Courses/${userId}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                setEnrollments(info);
            } else {
                setEnrollments([]);
            }
            setReady1(true);
        })();

        (async () => {
            const response = await fetch("/api/Courses/Available", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const info: Array<AvailableSemester> = await response.json();
                setAllCourses(info.sort(a => { return a.isEnrolled ? -1 : 1 }));
            } else {
                setAllCourses([]);
            }
            setReady2(true);
        })();
    }, [open]);

    useEffect(() => {
        if (ready1) {
            form.resetFields();
        }
    }, [ready1]);

    const setToServer = async (items: { new: Array<number>, removed: Array<number> }) => {
        for (let i = 0; i < items.removed.length; i++) {
            let response = await fetch("/api/Courses/DeleteEnroll", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    semesterId: items.removed[i],
                    userId: userId
                })
            });

            if (response.status !== 200) {
                message.error("Συνέβει ένα σφάλμα κατα την διαγραφή σας στο μάθημα");
                onCreate(items);
                return;
            }
        }

        for (let i = 0; i < items.new.length; i++) {
            let response = await fetch("/api/Courses/Enroll", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    semesterId: items.new[i],
                    userId: userId
                })
            });

            if (response.status !== 200) {
                message.error("Συνέβει ένα σφάλμα κατα την εγγραφή σας στο μάθημα");
                onCreate(items);
                return;
            }
        }

        onCreate(items);
    }

    return (
        <Modal open={open}
            width={"60%"}
            title="Εγγραφές μαθητή/καθηγητή"
            okText="Αποθήκευση"
            cancelText="Ακύρωση"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        if (!values.courses) {
                            return;
                        }

                        let prevValues = enrollments.map(e => e.semesterId);
                        let deleted = prevValues.filter(e => !values.courses.includes(e));
                        let newItems = values.courses.filter((e: number) => !prevValues.includes(e));
                        setReady1(false);
                        setReady2(false);
                        setToServer({ new: newItems, removed: deleted });
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            {(!ready1 || !ready2) && <Spin size="small" />}
            {ready1 && ready2 &&
                <Form
                    form={form}
                    disabled={disabled}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ courses: enrollments.map(e => e.semesterId) }}
                >
                    <Form.Item name="courses" label={`Εγγραφές σε μαθήματα για τον χρήστη ${userRealName}`} rules={[]}>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Επίλεξτε μαθήματα"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            notFoundContent={!ready1 || !ready2 ? <Spin size="small" /> : null}
                            options={allCourses.map(c => { return { label: `${c.course.name} - ${c.name}`, value: c.id } })}
                        />
                    </Form.Item>
                </Form>}
        </Modal>
    );
};

export default EnrollUserModal;