import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Checkbox, Form, Input, message, Modal, Pagination, Select, Space, Table, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { Skill, UserInfo } from "../models/models";


const SkillsTable: React.FC<{ skills: Array<Skill>, setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({ skills: skills, setReady, ...props }) => {
    const [showUpdateModal, setShowUpdateModal] = useState<Skill & { isCategoryInsert: boolean } | null>(null);
    const [orderedSkills, setOrderedSkills] = useState<Array<Skill>>([]);
    const [skillFilter, setSkillFilter] = useState("");


    const [form] = Form.useForm();

    useEffect(() => {
        if (!skills) {
            return;
        }

        let parents = skills.filter(x => !x.parent);
        let children = skills.filter(x => x.parent);

        for (let x of children) {
            let index = parents.findIndex(obj => obj.id === x.parent);
            parents.splice(index + 1, 0, x);
        }

        setOrderedSkills(parents);
    }, [skills]);

    const columns: TableColumnsType<Skill> = [
        {
            title: 'Τίτλος', filterSearch: true, onFilter: (value: string | number | boolean, record: Skill) => record.name.toLowerCase().includes((value as string)),
            ellipsis: true, dataIndex: "name"
        },
        {
            title: '', fixed: "left", width: 350, render: (value, record, index) => {
                if (!record.parent) {
                    return <></>
                }

                return <Space wrap size={0} direction="horizontal">
                    <Button type="primary" onClick={() => { setShowUpdateModal({ id: value.id, name: value.name, parent: value.parent, isCategoryInsert: false }) }}>Επεξεργασία</Button>
                    <Button type="primary" danger onClick={() => { deleteSkill(value); }}><DeleteOutlined /></Button>
                </Space>
            }
        }
    ];

    const deleteSkillOk = async (skill: Skill) => {
        const response = await fetch(`/api/Skill/Update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                id: skill.id,
                name: skill.name,
                delete: true,
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την διαγραφή");
            return;
        }

        setReady(false);
    }

    const deleteSkill = (skill: Skill) => {
        Modal.confirm({
            title: 'Διαγραφή δεξιότητας',
            icon: <ExclamationCircleOutlined />,
            content: `Είστε σίγουρος οτι θέλετε να διαγράψετε τη δεξιότητα;`,
            okText: 'Ναι',
            cancelText: 'Όχι',
            onOk() {
                deleteSkillOk(skill);
            },
            onCancel() {
            },
        });
    }

    const addOrUpdateNew = async (model: { id?: number, name: string, parent: number }) => {
        const response = await fetch(`/api/Skill/Update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                id: model?.id ?? null,
                name: model.name,
                parent: model.parent,
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα εισαγωγή νέας δεξιότητας");
            return;
        }

        setReady(false);
    }

    useEffect(() => {
        if (showUpdateModal) {
            form.setFieldsValue(showUpdateModal);
        }
        else {
            form.setFieldsValue({});
        }
    }, [showUpdateModal]);

    const getOptions = () => {
        let a: Array<{ value: number | undefined | null, label: string }> = skills.filter(s => !s.parent).map(s => { return { value: s.id, label: s.name } })
        return a;
    }

    return <>
        <Card title="Δεξιότητες" bordered hoverable className="always" style={{ marginTop: "24px" }}
            extra={[<Button type="primary" onClick={() => { setShowUpdateModal({ id: -1, name: "", isCategoryInsert: false }); }}>Προσθήκη νέας</Button>, <Button type="primary" onClick={() => { setShowUpdateModal({ id: -1, name: "", isCategoryInsert: true }); }}>Προσθήκη νέας κατηγορίας</Button>]}
        >

            <Table size="small" rowKey="id" columns={columns} dataSource={orderedSkills.filter(s => s.name.toLowerCase().includes(skillFilter))}
                rowClassName={(record, index) => { return record.parent ? "" : "header-col" }}
                pagination={{ position: ["topRight"], responsive: true, defaultPageSize: 20, total: skills.length, showSizeChanger: true, showQuickJumper: true, showTotal: (total) => <Space direction="horizontal" size={5}><Input.Search value={skillFilter} onChange={(e) => { setSkillFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton /> <span>{total} Δεξιότητες</span></Space> }}></Table>
            <Modal
                open={showUpdateModal != null}
                title="Προσθήκη/αλλαγή δεξιότητας"
                okText="Αποθήκευση"
                cancelText="Ακύρωση"
                onCancel={() => {
                    form.resetFields();
                    setShowUpdateModal(null);
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            setShowUpdateModal(null);
                            addOrUpdateNew({ ...values, id: (showUpdateModal?.id ?? -1) == -1 ? undefined : showUpdateModal?.id });
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={showUpdateModal ?? {}}
                >
                    <Form.Item
                        name="name"
                        label="Τίτλος"
                        rules={[{ required: true, message: 'Εισάγετε τον τίτλο της δεξιότητας' }]}
                    >
                        <Input></Input>
                    </Form.Item>
                    {!showUpdateModal?.isCategoryInsert &&
                        <Form.Item
                            name="parent"
                            label="Κατηγορία"
                            rules={[{ required: true, message: 'Επιλέξτε κατηγορία' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Κατηγορία"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={getOptions()}
                            />
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </Card>
    </>
}



export default SkillsTable;