import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Navigate, Route, RouterProps, BrowserRouter, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ExternalRedirect from "./pages/External";
import Dashboard from "./pages/Dashboard";
import DashboardLayout from "./pages/DashboardLayout";
import { UserInfo } from "./models/models";
import Course from "./pages/Course";
import ProfessorCourse from "./pages/ProfessorCourse";

const InitialAppContext = {
  isLoggedIn: false as boolean,
  user: null as UserInfo | null,
};

export const AppContext = React.createContext<[typeof InitialAppContext, Dispatch<SetStateAction<typeof InitialAppContext>>]>([InitialAppContext, () => { }]);

function App() {
  const [context, setContext] = useState<typeof InitialAppContext>(InitialAppContext);
  const location = useLocation();
  const [ready, setReady] = useState<Boolean>(false);

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/User", {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });


      if (response.status === 200) {
        const info = await response.json();
        setContext((p) => {
          return { ...p, isLoggedIn: true, user: info };
        });
      } else {
        setContext((p) => {
          return { ...p, isLoggedIn: false, user: null };
        });
      }

      setReady(true);
    })();

  }, [location.pathname]);

  return (
    <AppContext.Provider value={[context, setContext]}>
      <div className="App">
        {!ready ? (
          <div></div>
        ) :
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            {/* <Route path="/welcome" render={() => { return context.isLoggedIn ? <Redirect to="/dashboard" /> : <LandingLayout><Welcome /></LandingLayout>; }} /> */}
            <Route path="/dashboard" element={
              context.isLoggedIn ? <DashboardLayout><Dashboard /></DashboardLayout> : <Navigate to="/external" replace state={{ url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) }} />}
            />
            <Route path="/course/:id" element={<DashboardLayout><Course /></DashboardLayout>} />
            <Route path="/professorCourse/:id" element={<DashboardLayout><ProfessorCourse /></DashboardLayout>} />
            <Route path="/external" element={<ExternalRedirect />} />
          </Routes>
        }
      </div>
    </AppContext.Provider >
  );
}

export default App;
