import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Checkbox, Input, message, Modal, Pagination, Space, Table, TableColumnsType } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { UserInfo } from "../models/models";
import ChangeProfessorSkills from "./ChangeProfessorSkills";
import EditUserModal from "./EditUserModal";
import EnrollUserModal from "./EnrollUserModal";

const UsersTable: React.FC<{ users: Array<UserInfo>, setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({ users, setReady, ...props }) => {
    const [enrollStudentChange, setEnrollStudentChange] = useState<{ id: string, name: string } | null>(null);
    const [editUserChange, setEditUserChange] = useState<UserInfo | null>(null);
    const [userSkills, setUserSkills] = useState<UserInfo | null>(null);
    const [usersFilter, setUserFilter] = useState("");

    const columns: TableColumnsType<UserInfo> = [
        {
            title: 'Email', filterSearch: true, onFilter: (value: string | number | boolean, record: UserInfo) => record.email.toLowerCase().includes((value as string)),
            sorter: (a, b) => a.email.localeCompare(b.email), ellipsis: true, dataIndex: "email"
        },
        { title: 'Όνομα', sorter: (a, b) => a.lastName.localeCompare(b.lastName), ellipsis: true, render: (value, record, index) => { return value.firstName + " " + value.lastName } },
        { title: 'Ημ/νια Εγγραφής', sorter: (a, b) => moment(a.registrationDate).diff(moment(b.registrationDate)), ellipsis: true, render: (value, record, index) => { return !value.registrationDate ? "" : moment(value.registrationDate).format("ddd, DD MMM YYYY") } },
        {
            title: '', fixed: "left", width: 350, render: (value, record, index) => {
                return <Space wrap size={0} direction="horizontal">
                    <Button type="primary" onClick={() => { setEditUserChange(value) }}>Στοιχεία</Button>
                    <Button type="primary" onClick={() => { setEnrollStudentChange({ id: value.id, name: value.firstName + " " + value.lastName + " (" + value.email + ")" }); }}>Εγγραφές</Button>
                    <Button type="primary" onClick={() => { setUserSkills(value) }}>Δεξιότητες</Button>
                </Space>
            }
        }
    ];

    return <>
        <Table size="small" rowKey="id" columns={columns} dataSource={users.filter(u => u.lastName.toLowerCase().includes(usersFilter) || u.firstName.toLowerCase().includes(usersFilter) || u.email.toLowerCase().includes(usersFilter))}
            pagination={{ position: ["topRight"], responsive: true, defaultPageSize: 20, total: users.length, showSizeChanger: true, showQuickJumper: true, showTotal: (total) => <Space direction="horizontal" size={5}><Input.Search value={usersFilter} onChange={(e) => { setUserFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton style={{}} /> <span>{total} Χρήστες</span></Space> }}></Table>
        <EnrollUserModal disabled={true} onCancel={() => { setEnrollStudentChange(null); }} onCreate={() => { setEnrollStudentChange(null); setReady(false); }} open={enrollStudentChange != null} userId={enrollStudentChange?.id ?? ""} userRealName={enrollStudentChange?.name} ></EnrollUserModal>
        <EditUserModal disabled={true} onCancel={() => { setEditUserChange(null); }} onSaved={() => { setEditUserChange(null); setReady(false); }} open={editUserChange != null} user={editUserChange}></EditUserModal>
        <ChangeProfessorSkills disabled={false} onCancel={() => { setUserSkills(null); }} onSaved={() => { setUserSkills(null); setReady(false); }} open={userSkills != null} user={userSkills}></ChangeProfessorSkills>
    </>
}



export default UsersTable;