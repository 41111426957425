import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { Button, Form, FormInstance, message, Table, TableColumnsType, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { CommentColumnType, StudentComment, WeekComment } from "../models/models";

const EditableContext = React.createContext<FormInstance<any> | null>(null);
const { Text, Link } = Typography;

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const ProfessorCommentTable: React.FC<{ w: WeekComment, setReady: React.Dispatch<React.SetStateAction<boolean>>, isFirst: boolean, semesterId: number }> = ({ w, setReady, semesterId, isFirst, ...props }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>([]);

    const columns: TableColumnsType<StudentComment> = [
        // { title: "", render: (value, record, index) => { return <Badge status={value.checked ? "success" : "processing"} /> } },
        Table.EXPAND_COLUMN,
        { title: "Σχόλιο φοιτητή", dataIndex: 'comment', ellipsis: true, width: "30%" },
        {
            title: <>Ημ/νία παρακολούθησης <br /> (Ημ/νία καταγραφής)</>, ellipsis: true, width: "15%",
            render: (value, record, index) => {
                return (<><Text>{record.commentDate?.format("ddd, DD MMM YYYY")}</Text><br /><Text type="secondary">({record.submittedDate?.format("ddd, DD MMM YYYY")})</Text></>)
            }
        },
        {
            title: "Σχόλια καθηγητή", ellipsis: true, dataIndex: 'professorText', width: "30%", render: (values, record, index) => {
                return <TextArea defaultValue={record.professorText} rows={1} onChange={(e) => professorCommentChange(record, e.target.value)}></TextArea>
            }
        },
        Table.SELECTION_COLUMN,
        {
            title: "", fixed: 'right',
            width: 120, key: "actions", render: (values, record, index) => { return <Button type="primary" onClick={() => { submitClick(record, values) }}>Υποβολή</Button> }
        },
    ];

    useEffect(() => {
        if (!w || !w.comments) {
            setSelectedRowKeys([]);
            return;
        }

        setSelectedRowKeys(w.comments.filter(c => c.checked && c.id != null).map(c => c.id ?? -1));
    }, [w]);

    const submitClick = async (record: StudentComment, values: any) => {
        console.log(record, values);


        let c = w.comments.map(com => {
            return {
                weekDate: com.commentDate,
                professorText: com?.professorText,
                id: com?.id,
                checked: (selectedRowKeys.filter(srk => srk == com.id).length > 0)
            }
        });

        console.log(w.comments, c);

        const response = await fetch(`/api/Courses/LabComments/${semesterId}/Check`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterId,
                comments: c
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα!");
            return;
        }

        message.success("Αποθηκεύτηκε");
        setReady(false);
    }

    const professorCommentChange = async (record: StudentComment, value: string) => {
        record.professorText = value;
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const selectRow = (record: any) => {
        const selectedRowKeysCp = [...selectedRowKeys];
        if (selectedRowKeysCp.indexOf(record.id ?? "") >= 0) {
            selectedRowKeysCp.splice(selectedRowKeysCp.indexOf(record.id ?? ""), 1);
        } else {
            selectedRowKeysCp.push(record.id ?? "");
        }

        setSelectedRowKeys(selectedRowKeysCp);
    }

    return <Table showHeader={isFirst}
        rowSelection={{ columnTitle: <></>, columnWidth: "70px", selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
        rowKey="id" columns={columns} dataSource={w.comments} pagination={false}
        scroll={{ x: "100%" }}

        // onRow={(r) => ({ onClick: () => { selectRow(r); } })}
        expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.comment}</p>,
        }}>
    </Table>
}

export default ProfessorCommentTable;