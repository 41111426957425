import { AutoComplete, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { useEffect } from "react";
import { EditCourseProps } from "../models/models";

const NewCourseModal: React.FC<EditCourseProps> = ({
    open,
    onCreate,
    onCancel,
    course
}) => {
    const [form] = Form.useForm();

    const options = [
        { value: 'Χειμερινό εξάμηνο 2022/2023' },
        { value: 'Εαρινό εξάμηνο 2023' }
    ];

    const coursesOptions = [
        { value: 'Νοσηλευτική στην Κοινότητα' },
        { value: 'Ψυχική Υγεία και Φροντίδα' },
        { value: 'Κλινική Άσκηση' }
    ];

    useEffect(() => {
        if (course) {
            form.setFieldsValue({ ...course });
        }

    }, [course]);

    return (
        <Modal
            open={open}
            title={(course?.id ?? false) ? "Επεξεργασία μαθήματος" : "Προσθήκη νέου μαθήματος"}
            okText="Αποθήκευση"
            cancelText="Ακύρωση"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onCreate({ ...values, id: course?.id });
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={course ?? {}}
            >
                <Form.Item
                    name="title"
                    label="Τίτλος"
                    rules={[{ required: true, message: 'Εισάγετε τον τίτλο του μαθήματος' }]}
                >
                    <AutoComplete
                        options={coursesOptions}
                        placeholder="Εισάγετε τον τίτλο του μαθήματος"
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item name="semester" label="Εξάμηνο" rules={[{ required: true, message: 'Εισάγετε το εξάμηνο του μαθήματος' }]}>
                    <AutoComplete
                        options={options}
                        placeholder="Εισάγετε εξάμηνο"
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item name="dates" label="Ημερομηνία Εναρξης/Λήξης"
                    rules={[{ required: true, message: 'Εισάγετε ημερομηνίες έναρξης και λήξης' }]}>
                    <DatePicker.RangePicker />
                </Form.Item>

                <Form.Item name="minimumComments" label="Ελάχιστος αριθμός παρουσιών" rules={[{ required: true, }]}>
                    <InputNumber min={1} max={24}></InputNumber>
                </Form.Item>

                <Form.Item name="code" label="Κωδικός μαθήματος" rules={[{ required: true, message: 'Εισάγετε το κωδικό του μαθήματος' }]}>
                    <Input placeholder="Κωδικός μαθήματος"></Input>
                </Form.Item>

                <Input.Group compact>
                    <Form.Item style={{ width: '50%' }} name="timeStart" label="Ώρα έναρξης (24h)" rules={[{ required: true, }]}>
                        <InputNumber min={1} max={24}></InputNumber>
                    </Form.Item>

                    <Form.Item style={{ width: '50%' }} name="timeEnd" label="Ώρα λήξης (24h)" rules={[{ required: true, }]}>
                        <InputNumber min={1} max={24}></InputNumber>
                    </Form.Item>
                </Input.Group>
            </Form>
        </Modal>
    );
};

export default NewCourseModal;