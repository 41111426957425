import { ArrowLeftOutlined, CaretLeftFilled, CaretLeftOutlined, CaretRightFilled, CaretRightOutlined, CheckCircleTwoTone, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Calendar, Card, Checkbox, Col, Divider, Form, Grid, message, Modal, PageHeader, Row, Space, Spin, Tabs, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import { Comments, SemesterComments, StudentComment, StudentPresence, WeekComment } from "../models/models";
import el_gr from 'antd/lib/calendar/locale/el_GR';
import { green, blue, red } from '@ant-design/colors';

const Course: React.FC = props => {
    const context = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [semesterComments, setSemesterComments] = useState<SemesterComments | null>(null);
    const [weeks, setWeeks] = useState<Array<WeekComment>>([]);
    const [presences, setPresences] = useState<Array<StudentPresence>>([]);
    const [presencesCount, setPresenceCount] = useState(0);
    const [disabledForm, setDisabledForm] = useState(false);
    let { id } = useParams();

    const [isTabsCollapsed, setIsTabsCollapsed] = useState(false);

    const screens = Grid.useBreakpoint();

    useEffect(() => {
        if (ready) {
            return;
        }

        (async () => {
            const response = await fetch(`/api/Courses/LabComments/${id}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
            });

            if (response.status === 200) {
                const info: SemesterComments = await response.json();

                if (info.comments) {
                    info.comments = info.comments.filter((c: Comments) => c.studentId == context[0]?.user?.id);
                }

                if (info.studentPresences) {
                    info.studentPresences = info.studentPresences.filter((c: StudentPresence) => c.studentId == context[0]?.user?.id);
                }

                setSemesterComments(info);
                setPresences(info.studentPresences);

                let sortedStp = info.studentPresences.sort((a, b) => a.date.valueOf() - b.date.valueOf());
                let count = 0;
                let lastDate: moment.Moment = moment("19700101");
                for (let i = 0; i < sortedStp.length; i++) {
                    if (moment(sortedStp[i].date).isSame(lastDate, 'day')) {
                        continue;
                    }
                    count++;
                    lastDate = moment(sortedStp[i].date);
                }

                setPresenceCount(count);
            } else {
                setSemesterComments(null);
                setPresences([]);
                setPresenceCount(0);
            }

            setReady(true);
        })();

    }, [ready, id]);

    useEffect(() => {
        if (!semesterComments) {
            setWeeks([]);
            return;
        }

        let start = moment(semesterComments.startDate).clone().utc().startOf('week');
        let end = moment(semesterComments.endDate).clone().utc().startOf('week');
        let week = start.clone();
        let weeksMoment = [];
        let weeks: Array<WeekComment> = [];

        while (week.isSameOrBefore(end, 'week')) {
            weeksMoment.push(week.clone());

            let commentsInWeek = semesterComments.comments.filter(c => c.studentId == context[0]?.user?.id && moment(c.date).startOf('week').isSame(week.clone().startOf('week'), 'week')) ?? null;
            if (isProfessorOrAdmin() && !commentsInWeek) {
                continue
            }

            commentsInWeek = commentsInWeek.filter((v, i, a) => a.findIndex(aa => aa.comment == v.comment) == i);

            weeks.push({
                weekDate: week.clone(),
                weekNumber: week.diff(start, 'week') + 1,
                comments: commentsInWeek.map(ciw => {
                    return {
                        checked: ciw?.checked ?? false,
                        comment: ciw?.comment ?? "",
                        id: ciw?.id ?? null,
                        commentDate: (ciw && ciw.date) ? moment(ciw.date) : null,
                        submittedDate: (ciw && ciw.submittedDate) ? moment(ciw.submittedDate) : null,
                        professorName: ciw?.professorName,
                        professorText: ciw?.professorText ?? "",
                        professorCheckedId: ciw?.professorCheckedId,
                    }
                })
            });
            week.add(1, 'week');
        }

        setWeeks(weeks);
    }, [semesterComments]);

    const isProfessorOrAdmin = () => {
        return context[0].user?.professor || context[0].user?.admin;
    }

    const formFinished = async (values: any, weekDate: moment.Moment, id: number | null) => {
        if (isProfessorOrAdmin() || !values || !values.comment) {
            return;
        }

        setDisabledForm(true);
        const response = await fetch(`/api/Courses/LabComments/${semesterComments?.semesterId}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterComments?.semesterId,
                weekDate: weekDate.toDate(),
                comment: values.comment,
                id: id
            })
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα!");
            setDisabledForm(false);
            return;
        }

        message.success("Αποθηκεύτηκε");
        setReady(false);
        setDisabledForm(false);
    }

    const getTabLabel = (weekDate: moment.Moment, weekNumber: number, comments: Array<StudentComment>) => {
        let isMobile = isTabsCollapsed ? "" : " Εβδομάδα";
        if (weekDate.isSameOrBefore(moment(), 'day')) {
            if (comments.length > 0) {
                let commentWeekAny = comments.filter(c => c.commentDate?.isSame(weekDate, 'week') && c.checked == true)?.[0] ?? { checked: false };
                let color: "success" | "warning" = commentWeekAny.checked ? "success" : "warning";
                return <Typography.Text type={color}>{weekNumber}ή{isMobile}</Typography.Text>;
            }

            return <Typography.Text>{weekNumber}ή{isMobile}</Typography.Text>;
        }
        else {
            return <Typography.Text type="secondary">{weekNumber}ή{isMobile}</Typography.Text>;
        }
    }

    const declarePresenceOk = async () => {
        const response = await fetch(`/api/Courses/StudentPresence`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                semesterId: semesterComments?.semesterId,
                userId: context[0].user?.id ?? ""
            })
        });

        if (response.status !== 200) {
            var msg = await response.text();
            msg ??= "Συνέβει ένα σφάλμα!";
            msg = msg.replace(/^\"+/, '').replace(/\"+$/, '');
            message.error(msg.trim());
            return;
        }

        message.success("Η παρουσία σας δηλώθηκε με ημερομηνία " + moment().format("ddd, DD MMM YYYY"));
        setReady(false);
    }

    const declarePresence = () => {
        Modal.confirm({
            title: 'Δήλωση παρουσίας',
            icon: <ExclamationCircleOutlined />,
            content: 'Θέλετε να δηλώσετε παρουσία με σημερινή ημερομηνία και ώρα;',
            okText: 'Ναι',
            cancelText: 'Όχι',
            onOk() {
                declarePresenceOk();
            },
            onCancel() {
            },
        });
    }

    const calendarTabs = <Tabs
        tabPosition="left"
        tabBarExtraContent={{ left: <Button type="text" onClick={() => { setIsTabsCollapsed(!isTabsCollapsed); }}>{isTabsCollapsed ? <CaretRightOutlined /> : <CaretLeftOutlined />}</Button> }}
        items={weeks.map(w => {
            return {
                label: getTabLabel(w.weekDate, w.weekNumber, w.comments),
                key: `${w.weekNumber}`,
                children:
                    <Card key={w.weekNumber} title={<Typography.Text style={{ fontSize: "90%" }}>{w.weekDate.format("ddd, DD MMM YYYY")} - {w.weekDate.clone().add(1, 'week').subtract(1, 'second').format("ddd, DD MMM YYYY")}</Typography.Text>} bordered hoverable className="always">
                        <Row gutter={16} justify="center">
                            {[0, 1, 2, 3, 4].map(i => {
                                let day = w.weekDate.clone().add(i, 'days');
                                let commentToday = w.comments.filter(c => c.commentDate?.isSame(day, 'day'))?.[0] ?? {
                                    checked: false,
                                    comment: "",
                                };

                                return (<>{screens["xs"] && i != 0 && <Divider />}
                                    <Col key={`${w.weekNumber} ${i}`
                                    } flex="auto" >
                                        <h3>{day.format("dddd")}</h3>
                                        <Form
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 14 }}
                                            layout="vertical"
                                            initialValues={{ professorChecked: commentToday.checked, professorText: commentToday.professorText, comment: commentToday.comment }}
                                            onFinish={(values) => { formFinished(values, day, commentToday.id); }}
                                            disabled={disabledForm || !w.weekDate.isSameOrBefore(moment(), 'day')}
                                        >
                                            {(isProfessorOrAdmin() || (!isProfessorOrAdmin() && commentToday.checked)) &&
                                                <>
                                                    <Typography.Paragraph copyable={false} disabled type={commentToday.checked ? "success" : "secondary"}>{commentToday.comment}</Typography.Paragraph>
                                                    <Typography.Text disabled type="secondary">{commentToday.commentDate?.format("ddd, DD MMM YYYY")}</Typography.Text>
                                                    {commentToday.professorText && <Card title="Σχόλιο καθηγητή">
                                                        <span className="ant-form-text">{commentToday.professorText}</span></Card>}
                                                </>
                                            }

                                            {!isProfessorOrAdmin() && !commentToday.checked &&
                                                <>
                                                    <Form.Item name="comment" wrapperCol={{ span: 24 }}>
                                                        <TextArea placeholder={`Τί έκανα την ${day.format("dddd")}`} rows={4} />
                                                    </Form.Item>
                                                    {commentToday.professorText && <Card title="Σχόλιο καθηγητή">
                                                        <span className="ant-form-text">{commentToday.professorText}</span>
                                                    </Card>}
                                                </>
                                            }

                                            {!isProfessorOrAdmin() && commentToday.checked && <>
                                                {commentToday.checked && <><br /><small>(Εγκρίθηκε απο {commentToday.professorName})</small></>}
                                            </>
                                            }
                                            {!isProfessorOrAdmin() && !commentToday.checked &&
                                                <Form.Item>
                                                    <Button block={screens["xs"]} type="primary" htmlType="submit">Υποβολή</Button>
                                                </Form.Item>
                                            }
                                        </Form>
                                    </Col></>)
                            })
                            }
                        </Row>
                    </Card>,
            };
        })}
    />;

    const dateCellRender = (value: moment.Moment) => {
        let foundDate = presences.filter(p => moment(p.date).isSame(value, 'day'));
        if (foundDate.length == 0) {
            return <></>
        }

        let localDate = moment.utc(foundDate[0].date).local();
        let color = blue[6];
        if (localDate.local().hours() < (semesterComments?.timeStart ?? 0)) {
            color = red[6];
        }

        if (localDate.local().hours() > (semesterComments?.timeEnd ?? 0)) {
            color = red[6];
        }

        color = foundDate.filter(fd => fd.checked).length > 0 ? green[6] : color;

        return (<Space direction="vertical" size={0}>
            <CheckCircleTwoTone twoToneColor={color} style={{ fontSize: "24px" }} />
            <Typography.Text>{localDate.local().format("LT")}</Typography.Text>
        </Space>);
    };

    const hasPresenceToday = () => {
        if (!ready) {
            return true;
        }

        let foundDate = presences.filter(p => moment(p.date).isSame(moment(), 'day'));
        if (foundDate.length == 0) {
            return false;
        }

        return true;
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (<><PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title={semesterComments && `${semesterComments.course.name} - ${semesterComments.name}`}
    >
    </PageHeader>
        <Content>
            {!semesterComments && <><Spin indicator={antIcon} size="large" /><Typography.Text>  Παρακαλώ περιμένετε</Typography.Text></>}
            {semesterComments &&
                <><h3>{`${moment(semesterComments?.startDate).format("ddd, DD MMM YYYY")} - ${moment(semesterComments?.endDate).format("ddd, DD MMM YYYY")}`}</h3>
                    <Row justify="end" style={{ marginBottom: "5px" }}>
                        <Button size={screens["xs"] ? "large" : "middle"} disabled={hasPresenceToday()} block={screens["xs"]} type="primary" danger onClick={declarePresence}>Δήλωση παρουσίας</Button>
                    </Row>
                    <Tabs
                        tabPosition="top"
                        items={[
                            {
                                label: <span style={{ fontSize: "16px" }}>Ημερολόγιο</span>,
                                key: "0",
                                children: calendarTabs

                            },
                            {
                                label: <span style={{ fontSize: "16px" }}>Παρουσιες</span>, key: "1",
                                children: <>
                                    <Row justify="end">
                                        <Typography.Title style={{ marginRight: "25px" }} level={4}>Σύνολο παρουσιών <strong> {presencesCount}</strong></Typography.Title>
                                    </Row>
                                    <Calendar fullscreen={true} mode="month" locale={el_gr} validRange={[moment(semesterComments?.startDate), moment(semesterComments?.endDate)]} dateCellRender={dateCellRender} />
                                </>
                            }
                        ]}>
                    </Tabs>
                </>
            }
        </Content>
    </>);
}

export default Course;